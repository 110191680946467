<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="标签:">
                    <el-input class="s-input" v-model="queryParams.name" placeholder="请输入标签" clearable></el-input>
                </el-form-item>
                <el-form-item label="标签分类:">
                    <el-select v-model="queryParams.types" placeholder="请选择标签分类" clearable @change="changeQuery">
                        <el-option v-for="(typesItem,typesIndex)  in CommonTagTypeDict" :key="typesIndex" :label="typesItem" :value="typesIndex"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">添加标签</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list">
                <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                <el-table-column label="标签" prop="name" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="分类" prop="types" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary">{{ CommonTagTypeMap(scope.row.types) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="50%" class="dialog_box">
            <el-form class="form-box" ref="form" v-bind:rules="rules" label-position="left" v-bind:model="form" v-loading.body="loading" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                <el-form-item label="标签" prop="name">
                    <el-input type="text" auto-complete="off" v-model="form.name" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-select v-model="form.state" placeholder="请选择状态">
                        <el-option label="上线" v-bind:value="2"></el-option>
                        <el-option label="下线" v-bind:value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标签分类" prop="types">
                    <el-select v-model="form.types" placeholder="请选择标签分类">
                        <el-option v-for="(typesItem,typesIndex)  in CommonTagTypeDict" :key="typesIndex" :label="typesItem" :value="typesIndex"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import { CommonTagTypeDict, IMAGE_UPLOAD_PRO_URL, CommonTagTypeMap } from '@/common/const';

export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL: IMAGE_UPLOAD_PRO_URL,
            pickerOptions: {
                disabledDate(time) {
                    // return time.getTime() < Date.now() - 8.64e7;
                }
            },
            menuList: ['店铺', '标签管理'],
            loading: false,
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            CommonTagTypeDict: CommonTagTypeDict,
            pageInfo: {},
            addVisible: false,
            title: '添加热词',
            rules: {
                name: [{
                    required: true,
                    message: '名称不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            form: {
                name: '',
                state: null,
                time: null
            }
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        CommonTagTypeMap(intType) {
            return CommonTagTypeMap(intType)
        },
        cellstyle() {
            return "text-align: center";
        },
        //获取列表
        loadData() {
            this.loading = true;
            request.get('/common/tag/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //新增编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/common/tag/add' : '/common/tag/edit';

                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.loadData();
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        // 编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑标签';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            let time = [item.ctime, item.etime]
            item.platform = [item.platform]
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'ctime', time);
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 新增
        add() {
            this.mode = 'add';
            this.title = '新增标签';
            this.form = {
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
    }
}
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>
